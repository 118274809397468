.container{
    width: 100%;
    height: 80px;
    background-color:   rgb(20 83 207 / 76%);
    justify-content: center;
    align-items: center;
    display: flex;
}



.section{
    justify-content: space-between;
    align-items: center;
    display: flex;
gap: 30px;
    width: 70%;
    height: 90%;
 
}
.title{
    font-size: 18px;
    font-weight: 500;
    color: azure;
    cursor: pointer;
}
@media (max-width: 1170px) {
    .section{
        width: 80%;
    
    }
}
@media (max-width: 1027px) {
    .section{
        width: 90%;
        gap: 20px;
    }
}
@media (max-width: 886px) {
    /* .section{
        flex-direction: column;
        width: 340px;
       padding: 20px;
    } */
    /* .container{
        width: 100%;
        height: 100%;
} */
.title{
    text-align: left;
    font-size: 12px;
}
.section{
    width: 70%;
    /* align-items: flex-start; */
}
}
@media (max-width: 786px) {
    .section{
        width: 85%;
    }
}
@media (max-width: 640px) {
    .section{
        width: 95%;
    }
       /* .section{
        flex-direction: column;
        width: 340px;
       padding: 20px;
    } */
    /* .container{
        width: 100%;
        height: 100%;
} */
}
@media (max-width: 579px) {
 .section{
        flex-direction: column;
        width: 240px;
       padding: 20px;
      
     align-items: flex-start;
    }
    .container{
        width: 100%;
        height: 100%;
}
}