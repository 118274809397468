@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

.navbar {
    border-top: 8px solid blue;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding: 15px 60px;
    background-color: white;
    z-index: 1;
}

.logo {
    width: 130px;
    height: auto;
    cursor: pointer;
}

.navmneu {
    color: rgb(113, 118, 118);
    margin: 18px;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
}

.frontPage {
    height: fit-content;
    width: '100%';
}

.home {
    width: 100%;
    height: calc(100vh - 71px);
    background: url('../../public/heropic.png');
    background-size: cover;
}

.rgba {
    background-color: rgba(87, 90, 90, 0);
    width: 100%;
    height: calc(100vh - 71px);
    display: flex;
}

.heading {
    padding-left: 60px;
    color: rgb(87, 90, 90);
    font-size: 12px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 10;
}

.page {
    height: fit-content;
    color: rgb(113, 118, 118);
}

.flex {
    padding: 25px 60px;
    background-color: rgb(20 83 207 / 76%);
    margin-bottom: 80px;
}

.width {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    width: 90%;
}

.headtext {
    color: white;
    padding: 20px;
    letter-spacing: 0.5px;
    width: 65%;
}

.headtext h3 {
    margin-bottom: 0;
}

.form {
    background-color: whitesmoke;
    padding: 18px;
    color: rgb(73, 75, 75);
    width: 300px;
    margin-bottom: -90px;
    margin-top: 20px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.input {
    background-color: white;
    border: none;
    padding: 5px 5px;
    color: rgb(73, 75, 75);
    width: 95%;
    margin-top: 15px;
    font-size: 15px;
}

.input:focus {
    outline-color: rgba(178, 182, 188, 0.4);
}

.btn {
    border: none;
    font-size: 14px;
    color: white;
    text-transform: uppercase;
    padding: 10px 22px;
    border-radius: 5px;
    background-color: rgb(23, 23, 240);
    transition: all ease-in 0.6s;
}

.btn:hover,
.vies:hover,
.fix:hover {
    background-color: rgb(49, 49, 224);
}

.btn:focus,
.view:focus,
.fix:focus {
    transform: scale(0.96);
}

.view {
    border: none;
    font-size: 12px;
    color: white;
    padding: 8px auto;
    width: 16.66%;
    background-color: rgb(23, 23, 240);
    transition: all ease-in 0.6s;
}

.otherbtn {
    font-size: 14px;
    color: blue;
    text-transform: uppercase;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid rgb(23, 23, 240);
}

.otherbtn:focus {
    color: white;
    background-color: blue;
}

.head {
    font-weight: 500;
    font-size: 21px;
    color: black;
}

.padding {
    width: 90%;
    margin: auto;
}

.sideGap {
    width: 80%;
    margin: auto;
}

.background {
    background-color: whitesmoke;
    padding: 50px;
}

.icons {
    width: 80px;
    height: auto;
}

.program {
    display: flex;
    justify-content: space-between;
}

.twoBox {
    display: flex;
    color: #5e5d5d;
}

.singleBox {
    border: 1px solid #4dc0f0;
    width: 256px;
    height: 154px;
    font-weight: 400;
    border-top: 0;
    border-left: 0;
    padding: 20px;
    padding-left: 25px;
    padding-bottom: 15px;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    align-items: center;
    font-size: 16.5px;
    grid-gap: 30px;
}

.svg {
    width: 54px;
    height: 49px;
    align-self: center;
}

.secondClass {
    width: 90%;
    margin: auto;
}

.contentBox {
    margin: 10px 50px;
    border: 1px solid rgb(205, 205, 205);
    padding: 20px 15px;
    max-width: 350px;
}

.hr {
    border: 1px solid rgb(85, 84, 84);
    margin-top: -15px;
}

.btns {
    justify-content: space-between;
    display: flex;
    margin: auto 15px
}

.subject {
    min-width: 220px;
    margin-left: 20px;
    margin-right: 20px;
}

li {
    margin-top: 10px;
}

.png {
    width: 150px;
    height: auto;
}

.png2 {
    width: 130px;
    margin: 10px;
    height: auto;
}

.relative {
    position: relative;
    margin-top: 160px;
}

.absolute {
    position: absolute;
    color: red;
    left: 0;
    top: -120px;
    font-weight: 500;
    font-size: 14px;
}

.borderLeft {
    border-left: 1px solid red;
    height: 25px;
    width: 1px;
    margin: auto;
}

.items {
    display: flex;
    height: 34px;
}

.box {
    border-left: 1px solid gray;
    text-align: center;
    width: 16.66%;
    background-color: whitesmoke;
    height: 34px;
    padding: 5px;
    position: relative;
    color: rgb(64, 63, 63);
}

.headline {
    background-color: rgb(236, 233, 233);
    font-weight: 500;
    font-size: 17px;
    color: black;
    text-decoration: none;
    padding: 5px 8px;
}

.button {
    padding: 10px 25px;
    border: none;
    outline: none;
    font-size: 16px;
}

.info {
    width: 115px;
    height: 100%;
    min-width: 90px;
    margin: 5px;
    font-size: 12px;
    background-color: rgb(230 240 247);
    color: black;
    align-items: center;
    display: flex;
    text-align: center;
}

.boxes {
    display: flex;
    align-items: center;
    height: 95px;
}

.borderBottom {
    border: 1px solid rgb(0, 0, 0);
    width: 15px;
    height: 1px !important;
}

.space {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.enrol {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.enrol p {
    margin: 0;
}

.between {
    justify-content: space-around;
    display: flex;
    width: 50%;
}

.backImage {
    width: 105px;
    background-position: center 50%;
    background-repeat: no-repeat;
    height: 100%;
    min-width: 80px;
    justify-content: center;
    display: flex;
    align-items: center;
    background-image: url('../../public/Group.png');
    background-size: contain;
    color: black;
}

.fix {
    border: none;
    font-size: 12px;
    color: white;
    text-transform: uppercase;
    padding: 5px 20px;
    border-radius: 5px;
    background-color: rgb(23, 23, 240);
    transition: all ease-in 0.6s;
}

.container {
    width: 80%;
    height: fit-content;
    margin: auto;
    color: black;
}

.tablecontainer {
    width: 80%;
    margin: auto;
}

.half {
    width: 50%;
    border: 2px solid rgb(197, 195, 195);
}

.fixHeight {
    height: 110px;
}

@media screen and (max-width: 1199px) {
    .sideGap {
        width: 95%;
        margin: auto;
    }
}

@media screen and (max-width: 1087px) {

    .singleBox,
    .twoBox {
        width: 100%;
        justify-content: center;
    }
}

@media screen and (max-width: 1021px) {

    .otherbtn,
    .btn {
        padding: 5px 15px;
        font-size: 12px;
    }

    .fixHeight {
        height: 130px;
    }
}

@media screen and (max-width: 973px) {
    .between {
        margin: 20px 0;
        width: 100%;
    }
}

@media screen and (max-width: 875px) {
    .absolute {
        font-size: 13px;
        background-color: white;
        margin-left: -55px;
    }

    .fix {
        padding: 3px 10px;
    }
}

@media screen and (max-width: 805px) {
    .box {
        font-size: 14px;
    }

    .fix {
        padding: 5px 16px;
    }

    .hide {
        display: none;
    }

    .home {
        background-position: top 2px right -13rem;
    }

    .heading {
        color: white;
    }

    .rgba {
        background-color: rgba(87, 90, 90, 0.3);
    }

    .fixHeight {
        height: 140px;
    }
}

@media screen and (max-width: 695px) {
    .program {
        flex-direction: column;
    }

    .subject {
        margin-left: 0px;
        margin-right: 0px;
    }

    .width {
        width: 100%;
    }

    .flex {
        padding: 25px 15px;
    }

    .background {
        padding: 15px;
    }

    .navbar {
        padding: 20px 15px;
    }

    .navmneu {
        margin: 7px;
        font-size: 13px;
    }

    .items {
        width: 100%;
        flex-wrap: wrap;
    }

    .view {
        min-width: 90px;
        height: 100%;
    }

    .box {
        width: 90px;
        height: 100%;
        background-color: rgb(225, 224, 224);
    }

    .absolute {
        display: none;
    }

    .headline {
        font-size: 15px;
    }

    .fix {
        padding: 7px;
    }

    .sideGap {
        width: 100%;
    }

    .secondClass {
        width: 100%;
    }

    .container {
        width: 98%;
    }

    .tablecontainer {
        width: 98%;
    }

    li {
        margin-top: 6px;
        margin-left: -10px;
    }

    .fixHeight {
        height: 137px;
    }
}

@media screen and (max-width: 547px) {
    .svg {
        width: 42px;
        height: auto;
    }

    .singleBox {
        width: 200px;
        height: 120px;
        font-size: 14px;
        grid-gap: 6px;
    }

    .png2 {
        margin: auto;
    }

    .png {
        margin-left: -15px;
    }
}

@media screen and (max-width: 525px) {
    .form {
        width: 270px;
    }

    .head {
        font-size: 18px;
    }
}

@media screen and (max-width: 439px) {

    .btn,
    .view {
        font-size: 10px;
        padding: 5px 18px;
    }

    .contentBox {
        margin: 10px;
        margin-top: 18px;
    }

    .heading {
        padding-left: 20px;
    }

    .width {
        flex-direction: column;
    }

    .headtext {
        padding: 10px;
        width: 90%;
        margin: auto;
    }

    .flex {
        padding: 15px 5px;
        font-size: 5px;
        margin-bottom: 100px;
    }

    .input {
        font-size: 13px;
    }
}

@media screen and (max-width: 305px) {
    .navbar {
        padding: 10px 5px;
    }

    .navmneu {
        margin: 5px;
    }

    .padding {
        width: 95%;
    }
}