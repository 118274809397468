.fontSize{
  font-size: 16px;
}
.fs2{
  font-size: 18px;
}
.fs3{
  font-size: 17px;
}
.heroText{
  font-size: 38px;
}
.btext, .btext2{
  font-size: 32px;
}
.highlight{
  font-size: 23px;
  color: #343441;
}
.fyp{
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1087px){
    .btext2{
      font-size: 23px;
    }
}
@media screen and (max-width: 973px){
  .fyp{
    display: block;
  }
  .highlight{
    font-size: 18px;
  }
}
@media screen and (max-width: 603px){
  .btext2{
    font-size: 22px;
  }
}
@media screen and (max-width: 525px){
  .fontSize{
    font-size: 15px;
  }
  .fs2{
    font-size: 16.5px;
  }
  .fs3{
    font-size: 15px;
  }
  .heroText{
    font-size: 32px;
  }
  .btext{
    font-size: 28px;
    text-align: center;
  }
  .btext2{
    font-size: 19px;
  }
}