.container{
    width: 100%;
    height: 100%;
   justify-content: center;
   display: flex;
   align-items: center;
   flex-direction: column;
   gap: 50px;
  }
  .section{
    justify-content: center;
    display: flex;
    align-items: center;
    width: 100%;
    height: 150px;
  background-color: rgb(20 83 207 / 76%);
  
  }
  .title{
  font-size: 38px;
    font-weight: 500;
    color: azure;
    cursor: pointer;
   text-align: center;
  
  }
  .section1 {
    width: 70%;
    margin: 0 auto;
    padding: 50px;
    font-family: 'Poppins', sans-serif; /* Ensure you import this font in your project */
    line-height: 1.6;
    color: #333;
    border-radius: 20px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1); /* Adjusted shadow for more pronounced effect */
    background-color: #fff; /* Add a background color to make the shadow visible */
     align-items: center;
      margin-bottom: 5rem;
  }
  .heading_01 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .heading_02 {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  
  h3 {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .text_01 {
    margin-bottom: 15px;
    text-align: justify;
  }
  
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  ul li {
    margin-bottom: 5px;
    font-weight: 600;
  }
  .textm4{
    width:90%;
  }
  @media (max-width: 980px) {
    .section1 {
        width: 80%;
      margin: 0 auto;
      padding: 30px;
      font-family: 'Poppins', sans-serif;
      line-height: 1.6;
      color: #333;
      border-radius: 20px;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1); 
      background-color: #fff; 
    
        margin-bottom: 5rem;
      }
  }
  @media (max-width: 550px) {
    .section{
        height: 150px;
    }
    .section1 {
        width: 90%;
      
      margin: 0 auto;
      padding: 20px;
      font-family: 'Poppins', sans-serif; /* Ensure you import this font in your project */
      line-height: 1.6;
      color: #333;
      border-radius: 20px;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1); /* Adjusted shadow for more pronounced effect */
      background-color: #fff; /* Add a background color to make the shadow visible */
      
        margin-bottom: 5rem;
      }
      .heading_01 {
        font-size: 20px;
        margin-bottom: 15px;
      }
      
      .heading_02 {
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 10px;
      }
      
     
      
      .text_01 {
        margin-bottom: 5px;
        text-align: justify;
      }
      .title{
       
    
        color: azure;
        cursor: pointer;
    }
  }